/* ==========================================================================
Components
  > Heroes
========================================================================== */

.c-hero {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: calc(100vh - var(--header__height) - var(--tap-target__size));
  border-bottom: 1px solid var(--color--border);

  // Content

  .c-hero__content {
    z-index: 3;
    margin: auto 0;
    padding: var(--gutter);

    @include bp-small {
      padding: 40px;
    }

    @include bp-medium {
      padding: 64px;
    }

    @include bp-xlarge {
      padding: 80px;
    }

    @include bp-xxlarge {
      padding: 96px;
    }

    @include bp-largest {
      padding: 112px;
    }

    .c-hero__content__item {
      @include t-banner--ui;
    }
  }

  // Media

  .c-hero__media {
    position: absolute;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    img:-moz-loading {
      visibility: hidden;
    }
  }

  &[data-article-hero__layout='100'] {
    h1 {
      font-size: var(--font__size--h1--hero);
    }
  }
}

/* ==========================================================================
Data attribute controls available:

  data-component__theme                 [light dark]
  data-article-hero__size               [mini large]
  data-article-hero__layout             [50-50 100]
  data-article-hero__media              [true false | true]
  data-article-hero__protection         [full transparency gradient none]
  data-article-hero__text-alignment     [left centre]
  data-article-hero__art-direction      [true false | true]
  data-article-hero__img-position       [center top bottom top-right]

========================================================================== */

/* ==========================================================================
  Size
========================================================================== */

// Mini

[data-article-hero__size='mini'] {
  min-height: 300px;

  @include bp-large {
    &[data-article-hero__layout='50-50'] {
      min-height: 640px;
    }

    &[data-article-hero__layout='100'] {
      min-height: 400px;
    }
  }
}

/* ==========================================================================
  Layout
========================================================================== */

// 50-50

[data-article-hero__layout='50-50'] {
  .c-hero__content,
  .c-hero__media {
    position: relative;
  }

  .c-hero__media {
    height: auto;
    overflow: hidden;
  }

  .c-hero__content {
    .c-hero__content__item {
      p {
        width: 100%;
      }
    }
  }

  @include bp-max-large {
    height: auto;
    max-height: none;
  }

  @include bp-large {
    flex-direction: row;

    .c-hero__content {
      display: flex;
      align-items: center;
      width: 50%;
    }

    .c-hero__media {
      position: relative;
      width: 50%;

      > * {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
      }
    }
  }
}

// 50-100

@include bp-medium-large {
  [data-article-hero__layout='50-100'] {
    .c-hero__content {
      order: 1;
      width: 50%;
    }

    .c-hero__media {
      position: absolute;
      top: var(--header__height);
      z-index: 2;
      width: 100%;
      height: calc(100vh - var(--header__height));
    }
  }
}

// 100

[data-article-hero__layout='100'] {
  flex-direction: column;
}

/* ==========================================================================
  Media
========================================================================== */

// False

[data-article-hero__media='false'] {
  min-height: 0;
}

/* ==========================================================================
  Protection
========================================================================== */

// All

[data-article-hero__protection] {
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}

// Full

[data-article-hero__protection='full'] {
  &:before {
    background: var(--color--primary--dark);
    opacity: 0.86;
  }
}

// Full - Light Theme

[data-component__theme='light'][data-article-hero__protection='full'] {
  &:before {
    background: var(--color--primary--lightest);
    opacity: 0.86;
  }
}

// Transparency

[data-article-hero__protection='transparency'] {
  &:before {
    background: rgba(0, 0, 0, 0.48);
  }

  &[data-component__theme='light'] {
    &:before {
      background: rgba(255, 255, 255, 0.64);
    }
  }
}

// Transparency - Primary @ max ml

@include bp-max-medium-large {
  [data-article-hero__protection--mobile='transparency--primary--max--ml'] {
    .c-hero__content {
      background: var(--color--primary--transparency);
    }
  }
}

// Gradient

[data-article-hero__protection='gradient'] {
  &:before {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.32) 56%,
      rgba(0, 0, 0, 0.64) 100%
    );
  }

  &[data-component__theme='light'] {
    &:before {
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.64) 56%,
        rgba(255, 255, 255, 0.86) 100%
      );
    }
  }
}

/* ==========================================================================
  Text Alignment
========================================================================== */

// Left

[data-article-hero__text-alignment='left'] {
  text-align: left;
}

// Centre

[data-article-hero__text-alignment='centre'] {
  text-align: center;
  justify-content: center;
  // align-items: center;

  .c-hero__content {
    margin-inline: auto;
    bottom: auto;
    left: auto;

    .c-hero__content__item {
      p {
        margin-inline: auto;
      }

      .c-btns {
        justify-content: center;
      }

      .c-btn {
        margin-inline: 8px;
      }
    }

    @include bp-medium-large {
      margin: auto;
    }
  }
}

/* ==========================================================================
  Image Position
========================================================================== */

// Top

[data-article-hero__img-position='top'] {
  .c-hero__media {
    img {
      object-position: top;
    }
  }
}

// Bottom

[data-article-hero__img-position='bottom'] {
  .c-hero__media {
    img {
      object-position: bottom;
    }
  }
}
